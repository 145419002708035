import revive_payload_client_4sVQNw7RlN from "/var/www/vuejsexamples/nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/vuejsexamples/nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/vuejsexamples/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/vuejsexamples/nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/vuejsexamples/nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/vuejsexamples/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/vuejsexamples/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import useDirectives_xtsFPcXgOx from "/var/www/vuejsexamples/nuxt/src/modules/runtime/useDirectives.mjs";
import _001_notivue_client_bE2FHKqHiu from "/var/www/vuejsexamples/nuxt/.nuxt/001.notivue.client.mjs";
import plugin_client_i8AMfKeYnY from "/var/www/vuejsexamples/nuxt/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import i18n_yfWm7jX06p from "/var/www/vuejsexamples/nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_t2GMTTFnMT from "/var/www/vuejsexamples/nuxt/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/vuejsexamples/nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/vuejsexamples/nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_1UohGbtF8v from "/var/www/vuejsexamples/nuxt/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import bootstrap_GlqbrnFWUq from "/var/www/vuejsexamples/nuxt/src/plugins/bootstrap.ts";
import cookies_U7piddfsy3 from "/var/www/vuejsexamples/nuxt/src/plugins/cookies.ts";
import error_handler_eQzajpVw3N from "/var/www/vuejsexamples/nuxt/src/plugins/error-handler.ts";
import fontawesome_CH30yp3mE1 from "/var/www/vuejsexamples/nuxt/src/plugins/fontawesome.ts";
import i18nRoute_rhyBgoAgyw from "/var/www/vuejsexamples/nuxt/src/plugins/i18nRoute.ts";
import sentry_client_KAXFuL2wum from "/var/www/vuejsexamples/nuxt/src/plugins/sentry.client.ts";
import vue_tippy_client_422P8hGTDN from "/var/www/vuejsexamples/nuxt/src/plugins/vue-tippy.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  useDirectives_xtsFPcXgOx,
  _001_notivue_client_bE2FHKqHiu,
  plugin_client_i8AMfKeYnY,
  i18n_yfWm7jX06p,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_1UohGbtF8v,
  bootstrap_GlqbrnFWUq,
  cookies_U7piddfsy3,
  error_handler_eQzajpVw3N,
  fontawesome_CH30yp3mE1,
  i18nRoute_rhyBgoAgyw,
  sentry_client_KAXFuL2wum,
  vue_tippy_client_422P8hGTDN
]