export const appHead = {"meta":[{"charset":"utf-16"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","content":"Удобные подборки плагинов, пакетов, библиотек для вашего нового приложения или сайта"},{"name":"yandex-verification","content":"4a2d0773f351e368"}],"link":[{"rel":"stylesheet","href":"/css/preload.css"},{"rel":"icon","href":"https://itlibs.ru/favicon.ico","type":"image/x-icon"}],"style":[],"script":[{"src":"/js/yandex-metrika.js"},{"src":"https://www.googletagmanager.com/gtag/js?id=G-23Z8FE8VGW"},{"src":"/js/google-analytics.js"}],"noscript":[],"charset":"utf-16","viewport":"width=device-width, initial-scale=1","title":"Плагины и новости из мира IT"}

export const appBaseUrl = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnUrl = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'