import { default as indexcMyI4gIC8iMeta } from "/var/www/vuejsexamples/nuxt/src/pages/[slug]/[slug2]/[slug3]/index.vue?macro=true";
import { default as indexmRRUlYcfNqMeta } from "/var/www/vuejsexamples/nuxt/src/pages/[slug]/[slug2]/index.vue?macro=true";
import { default as indexzuYhQQRI28Meta } from "/var/www/vuejsexamples/nuxt/src/pages/[slug]/index.vue?macro=true";
import { default as _91slug_93R4ecZ7PlxwMeta } from "/var/www/vuejsexamples/nuxt/src/pages/articles/[id]/[slug].vue?macro=true";
import { default as indexfXWAK4pg3IMeta } from "/var/www/vuejsexamples/nuxt/src/pages/articles/[id]/index.vue?macro=true";
import { default as screenshotBYmq0wbrr2Meta } from "/var/www/vuejsexamples/nuxt/src/pages/articles/[id]/screenshot.vue?macro=true";
import { default as indexIfAP0XTU2HMeta } from "/var/www/vuejsexamples/nuxt/src/pages/index.vue?macro=true";
import { default as pagesIUpaP00eECMeta } from "/var/www/vuejsexamples/nuxt/src/pages/pages.vue?macro=true";
import { default as searchmUgSu4oLjuMeta } from "/var/www/vuejsexamples/nuxt/src/pages/search.vue?macro=true";
import { default as _91slug_93sR4QtvsNAxMeta } from "/var/www/vuejsexamples/nuxt/src/pages/tags/[slug].vue?macro=true";
export default [
  {
    name: indexcMyI4gIC8iMeta?.name ?? "slug-slug2-slug3",
    path: indexcMyI4gIC8iMeta?.path ?? "/:slug()/:slug2()/:slug3()",
    meta: indexcMyI4gIC8iMeta || {},
    alias: indexcMyI4gIC8iMeta?.alias || [],
    redirect: indexcMyI4gIC8iMeta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/nuxt/src/pages/[slug]/[slug2]/[slug3]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmRRUlYcfNqMeta?.name ?? "slug-slug2",
    path: indexmRRUlYcfNqMeta?.path ?? "/:slug()/:slug2()",
    meta: indexmRRUlYcfNqMeta || {},
    alias: indexmRRUlYcfNqMeta?.alias || [],
    redirect: indexmRRUlYcfNqMeta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/nuxt/src/pages/[slug]/[slug2]/index.vue").then(m => m.default || m)
  },
  {
    name: indexzuYhQQRI28Meta?.name ?? "slug",
    path: indexzuYhQQRI28Meta?.path ?? "/:slug()",
    meta: indexzuYhQQRI28Meta || {},
    alias: indexzuYhQQRI28Meta?.alias || [],
    redirect: indexzuYhQQRI28Meta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/nuxt/src/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R4ecZ7PlxwMeta?.name ?? "articles-id-slug",
    path: _91slug_93R4ecZ7PlxwMeta?.path ?? "/articles/:id()/:slug()",
    meta: _91slug_93R4ecZ7PlxwMeta || {},
    alias: _91slug_93R4ecZ7PlxwMeta?.alias || [],
    redirect: _91slug_93R4ecZ7PlxwMeta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/nuxt/src/pages/articles/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexfXWAK4pg3IMeta?.name ?? "articles-id",
    path: indexfXWAK4pg3IMeta?.path ?? "/articles/:id()",
    meta: indexfXWAK4pg3IMeta || {},
    alias: indexfXWAK4pg3IMeta?.alias || [],
    redirect: indexfXWAK4pg3IMeta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/nuxt/src/pages/articles/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: screenshotBYmq0wbrr2Meta?.name ?? "articles-id-screenshot",
    path: screenshotBYmq0wbrr2Meta?.path ?? "/articles/:id()/screenshot",
    meta: screenshotBYmq0wbrr2Meta || {},
    alias: screenshotBYmq0wbrr2Meta?.alias || [],
    redirect: screenshotBYmq0wbrr2Meta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/nuxt/src/pages/articles/[id]/screenshot.vue").then(m => m.default || m)
  },
  {
    name: indexIfAP0XTU2HMeta?.name ?? "index",
    path: indexIfAP0XTU2HMeta?.path ?? "/",
    meta: indexIfAP0XTU2HMeta || {},
    alias: indexIfAP0XTU2HMeta?.alias || [],
    redirect: indexIfAP0XTU2HMeta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/nuxt/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: pagesIUpaP00eECMeta?.name ?? "pages",
    path: pagesIUpaP00eECMeta?.path ?? "/pages",
    meta: pagesIUpaP00eECMeta || {},
    alias: pagesIUpaP00eECMeta?.alias || [],
    redirect: pagesIUpaP00eECMeta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/nuxt/src/pages/pages.vue").then(m => m.default || m)
  },
  {
    name: searchmUgSu4oLjuMeta?.name ?? "search",
    path: searchmUgSu4oLjuMeta?.path ?? "/search",
    meta: searchmUgSu4oLjuMeta || {},
    alias: searchmUgSu4oLjuMeta?.alias || [],
    redirect: searchmUgSu4oLjuMeta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/nuxt/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sR4QtvsNAxMeta?.name ?? "tags-slug",
    path: _91slug_93sR4QtvsNAxMeta?.path ?? "/tags/:slug()",
    meta: _91slug_93sR4QtvsNAxMeta || {},
    alias: _91slug_93sR4QtvsNAxMeta?.alias || [],
    redirect: _91slug_93sR4QtvsNAxMeta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/nuxt/src/pages/tags/[slug].vue").then(m => m.default || m)
  }
]